<template>
  <div class="ResultItemTitle">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ResultItemTitle {
  font-weight: 500;
}
</style>
